import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import SEO from '../components/seo'

import '../css/blog-post.scss'

const BlogPostPage = ({ data }) => (
  <article className="text-content m-auto style-links blog-post">
    <SEO
      title={data.post.title}
      description={data.post.excerpt} />
    <header>
      <Img className="overflow-hidden rounded mb-4 cover-image"
        fluid={data.post.coverImage.localFile.childImageSharp.fluid} />
      <h1 className="text-3xl font-semibold mb-4">{data.post.title}</h1>
      <div className="flex font-semibold mb-4 text-gray-500 uppercase text-sm mt-4">
        <h3 className="mr-2">{data.post.topic}</h3> /
        <h3 className="ml-2">{new Date(data.post.publishedOn).toDateString()}</h3>
      </div>
    </header>
    <section className="style-text">
      {documentToReactComponents(data.post.content.json)}
    </section>
  </article>
)

export const query = graphql`
  query BlogPostPageQuery($id: String) {
    post: contentfulBlogPost(id: {eq: $id}) {
      id
      title
      publishedOn
      topic
      excerpt
      coverImage {
        id
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      content {
        json
      }
    }
  }
`

export default BlogPostPage
